/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useRef, useState } from 'react';
import styled from '@emotion/styled';
import { theme } from './GlobalStyles';
import { navMenu } from './navMenu';
import { ButtonPrimary } from './StyledComponents';
import { Link } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';
import { Icon } from '@/Icon';

interface Props {
    active: boolean;
    menuName?: string | null;
}

const MobileNav = styled.button<Props>`
    height: 70px;
    box-shadow: ${({ active }) => (active ? 'none' : 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px')};
    display: flex;
    transition: box-shadow 0.2s ease-in;
    transition: background-color 0.2s ease-in;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    position: fixed;
    top: 0px;
    background: ${({ active }) => (active ? 'none' : `#ffffff`)};
    z-index: 10;
    width: 100vw;
    padding: 16px 16px;
    border: none;
    .logo {
        width: 213px;
    }

    @media ${theme.device.laptop} {
        display: none;
        margin: 15px 32px;

        .logo {
            display: none;
        }
    }
`;

const MobileMenu = styled.div<Props>`
    left: 0;
    top: 0;
    width: 100vw;
    z-index: 5;
    height: 100vh;
    background: #ffffff;
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding: 0px 0px;
    position: fixed;
    align-items: center;
    transition: transform 0.2s cubic-bezier(0.1, 0.7, 0.6, 0.9);
    transform: ${({ active }) => (active ? 'translateX(0%)' : 'translateX(100%)')};
    ul {
        position: absolute;
        flex-shrink: 0;
        top: ${({ active, menuName }) => (active && menuName !== 'Services' ? '300px' : '90px')};
        list-style: none;
        left: 80px;

        svg {
            position: absolute;
            right: -90px;
            width: 10px;
            fill: ${theme.colors.darkmodebg};
        }
        button {
            font-family: Poppins;
            color: ${theme.colors.darkmodebg};
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 162%;
            letter-spacing: 1.4px;
            margin-bottom: 40px;
            text-transform: uppercase;
            display: flex;
            align-items: center;
        }
    }
    li {
        margin-bottom: 40px;
        a {
            text-transform: uppercase;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 162%;
            color: ${theme.colors.darkmodebg};
        }
    }
    button {
        background: none;
        border: none;
    }
    .menu-selected-name {
        position: absolute;
        top: -100px;
        width: fit-content;
        left: 0px;
        min-width: 200px;
        display: ${({ active, menuName }) =>
            active && menuName !== 'Services' ? 'inline-block' : 'none'};
        p {
            text-decoration: underline;
            font-family: Poppins;
            color: ${theme.colors.darkmodebg};
            font-size: 14px;
            font-style: normal;

            font-weight: 400;
            line-height: 162%;
            letter-spacing: 1.4px;
        }
        svg {
            margin-right: 8px;
            fill: ${theme.colors.white};
            transform: rotate(90deg);
        }
    }
    @media ${theme.device.laptop} {
        display: none;
    }
`;

const StyledNav = styled.nav`
    width: 10vw;
    position: relative;
`;

const Burger = styled.div<Props>`
    background: none;
    border: none;
    display: flex;
    flex-direction: column;
    align-items: end;
    border: none;
    gap: 6px;
    background: none;
    margin-left: 32px;
    div {
        border-radius: 15px;
        height: 3px;
        background: ${theme.colors.darkmodebg};
    }
    .line-one {
        transition: 0.2s cubic-bezier(0.1, 0.7, 0.6, 0.9);
        position: relative;
        top: ${({ active }) => (!active ? '0' : '6px')};
        transform: ${({ active }) => (!active ? 'rotate(0%)' : 'rotate(45deg)')};
        width: ${({ active }) => (!active ? '24px' : '24px')};
    }
    .line-two {
        width: ${({ active }) => (!active ? '12px' : '0px')};
        transition: 0.5s cubic-bezier(0.1, 0.7, 0.6, 0.9);
    }
    .line-three {
        transition: 0.2s cubic-bezier(0.1, 0.7, 0.6, 0.9);
        position: relative;
        bottom: ${({ active }) => (!active ? '0' : '12px')};
        transform: ${({ active }) => (!active ? 'rotate(0%)' : 'rotate(-45deg)')};
        width: ${({ active }) => (!active ? '24px' : '24px')};
    }
    @media ${theme.device.laptop} {
        display: none;
    }
`;

interface NewProps {
    active: boolean;
}

const DesktopNav = styled.div<NewProps>`
    display: none;
    .logo {
        display: none;
    }
    @media ${theme.device.laptop} {
        display: flex;
        flex-direction: row;
        transition: box-shadow 0.2s ease-in;
        transition: background-color 0.2s ease-in;
        box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
        background: white;
        align-items: center;
        position: fixed;
        top: 0;
        width: 100vw;
        justify-content: space-between;
        z-index: 4;
        margin: 0px 0px 0px;
        padding: 16px 32px;
        .main-menu {
            display: flex;
            gap: 36px;
            position: relative;
            justify-content: space-between;
            list-style: none;
            > li {
                cursor: pointer;
                display: flex;
                align-items: center;
                transition: 0.2s ease-in;
                a {
                    color: ${theme.colors.darkmodebg};
                    text-decoration: none;
                }
                svg {
                    position: relative;
                    bottom: 2px;
                    height: 7px;
                    fill: ${theme.colors.gray4};
                    margin-left: 10px;
                    transition: 0.2s ease-in;
                }
                &:hover {
                    svg:not(.category-arrow) {
                        transform: rotate(180deg);
                    }
                }
            }
            > li {
                display: inline-block;
                position: relative;
            }
            > li:hover:after {
                transform: scaleX(1);
                transform-origin: left right;
            }
            > li:after {
                content: '';
                position: absolute;
                width: 50%;
                transform: scaleX(0);
                height: 2px;
                bottom: -3px;
                left: 24.9%;
                background-color: ${theme.colors.darkmodebg};
                transform-origin: left right;
                transition: transform 0.25s cubic-bezier(0.33, 1, 0.68, 1);
            }
        }

        .desktop-buttons {
            display: flex;
            align-items: stretch;

            button {
                margin-right: 20px;
            }
        }
        .logo-desktop {
            justify-self: end;
            display: block;
            width: 347px;
        }
    }
    @media ${theme.device.laptop} {
        .main-menu {
            left: 00px;
        }
    }
    @media ${theme.device.laptopL} {
        .main-menu {
            left: -80px;
        }
        .desktop-buttons {
            display: flex;
            button {
                margin-right: 20px;
            }
        }
    }
`;

const DesktopMenu = styled.ul`
    position: absolute;
    display: flex;
    flex-direction: column;
    background: #ffffff;
    min-width: 303px;
    opacity: 1;
    z-index: 2;
    box-shadow:
        0px 51px 125px rgba(109, 106, 93, 0.09),
        0px 18.5323px 39.5627px rgba(109, 106, 93, 0.0393285),
        0px 8.27833px 15.9688px rgba(109, 106, 93, 0.0425114),
        0px 4.0063px 6.7726px rgba(109, 106, 93, 0.0304631);
    border-radius: 15px;
    list-style: none;
    left: -16px;
    top: 50px;
    a {
        padding: 0px 70px 0 0;
        color: ${theme.colors.darkmodebg};
        text-decoration: none;
    }
    .category-item {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: space-between;
        .category-arrow {
            min-height: 12px;
            right: 20px;
            transform: rotate(0deg);
        }
        :hover {
            .category-arrow {
                transform: translate(3px);
            }
        }
    }
    li {
        transition: 0.2s ease-in;
        z-index: 0;
        padding: 18px 0 18px 18px;
        cursor: pointer;
        position: relative;
        &:hover {
            background: #f4efed;
        }
    }
    .sub-menu-name {
        cursor: default;
        :hover {
            background: none;
        }
    }
    li:first-of-type {
        border-radius: 15px 15px 0 0;
        padding-top: 16px;
    }
    li:last-of-type {
        border-radius: 0px 0px 15px 15px;
        padding-bottom: 16px;
        cursor: pointer;
    }
    &:hover {
        opacity: 1;
    }
`;

type Prop = {
    subMenuName?: string;
};

const DesktopSubMenu = styled(DesktopMenu)<Prop>`
    position: absolute;
    left: calc(100% - 42px);
    top: ${({ subMenuName }) =>
        (subMenuName === 'Restorative Dentistry' && '100px') ||
        (subMenuName === 'Dentures' && '150px') ||
        '50px'};
`;

interface MenuType {
    name: string;
    path?: string;
    menu?: MenuType[];
    sub_menu?: SubMenu;
    externalPath?: string;
}

type SubMenu = MenuType[];

export const Nav = () => {
    const [active, setActive] = useState(false);
    const [subActive, setSubActive] = useState(false);
    const [menuName, setMenuName] = useState<string | null>(null);
    const [menuState, setMenuState] = useState<MenuType | null>(null);
    const [subMenuState, setSubMenuState] = useState<SubMenu | null>(null);
    const [subMenuName, setSubMenuName] = useState<string | null>(null);
    const navRef = useRef(null);

    const handleNavClick = (e: { target: React.MutableRefObject<null> } | any) => {
        e.target !== navRef.current ? setActive(false) : setActive(true);
        e.target !== navRef.current ? setSubActive(false) : setSubActive(true);
    };

    return (
        <StyledNav onMouseLeave={handleNavClick}>
            <MobileNav active={active} className="">
                <>
                    {!active || !menuState ? (
                        <Link
                            onClick={() => {
                                setSubMenuState(null);
                                setSubActive(false);
                                setActive(false);
                                setMenuState(null);
                                setSubMenuName(null);
                            }}
                            aria-label="Western Carolina Logo"
                            to="/"
                        >
                            <StaticImage
                                alt="western carolina family dentistry logo"
                                src="../../images/common/logo.png"
                                placeholder="none"
                                quality={100}
                                className="logo"
                            />
                        </Link>
                    ) : (
                        <button
                            onClick={() => {
                                setMenuState(null);
                                setMenuName('');
                                setSubMenuState(null);
                            }}
                        >
                            <Icon name="back-arrow" />
                        </button>
                    )}

                    <Burger
                        onClick={() => {
                            setActive(active => !active);
                            setSubMenuState(null);
                            setMenuName(null);
                            setMenuState(null);
                        }}
                        active={active}
                    >
                        <div className="line-one"></div>
                        <div className="line-two"></div>
                        <div className="line-three"></div>
                    </Burger>
                </>
            </MobileNav>
            <MobileMenu menuName={menuName} active={active}>
                <ul>
                    {!subActive &&
                        !menuState &&
                        navMenu.map(({ menu, name }) => {
                            return (
                                <li
                                    onMouseEnter={() => {
                                        setActive(true);
                                        setSubActive(false);
                                        setMenuName(name);
                                        setMenuState(menu as any);
                                        setSubMenuState(null);
                                        setSubMenuName(null);
                                        setSubActive(false);
                                    }}
                                    key={name}
                                >
                                    <button>
                                        {name}
                                        <Icon name="arrow-nav" />
                                    </button>
                                </li>
                            );
                        })}
                </ul>
                {
                    <ul>
                        {menuState && (
                            <button className="menu-selected-name">
                                <p>{menuName}</p>
                            </button>
                        )}
                        {menuState &&
                            Array.isArray(menuState) &&
                            menuState.map(subItems => {
                                if (!subItems.sub_menu) {
                                    return (
                                        <li key={subItems.name}>
                                            <Link
                                                onClick={() => {
                                                    setSubMenuState(null);
                                                    setSubActive(false);
                                                    setActive(false);
                                                    setMenuState(null);
                                                    setSubMenuName(null);
                                                }}
                                                to={subItems.path || ''}
                                            >
                                                {subItems.name}
                                            </Link>
                                        </li>
                                    );
                                } else {
                                    return (
                                        // eslint-disable-next-line jsx-a11y/click-events-have-key-events
                                        // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions
                                        <li
                                            onClick={() => {
                                                setSubMenuState(subItems.sub_menu);
                                                setSubActive(true);
                                                setMenuState(null);
                                                setSubMenuName(null);
                                            }}
                                            key={subItems.name}
                                        >
                                            {subItems.name} <Icon name="arrow-nav" />
                                        </li>
                                    );
                                }
                            })}
                        {setSubActive &&
                            subActive &&
                            subMenuName &&
                            Array.isArray(menuState) &&
                            menuState.map(subItems => {
                                return (
                                    // eslint-disable-next-line jsx-a11y/click-events-have-key-events
                                    // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions
                                    <li
                                        onClick={() => {
                                            setSubMenuState(subItems.sub_menu);
                                            setSubActive(true);
                                            setMenuState(null);
                                            setSubMenuName(null);
                                        }}
                                        key={subItems.name}
                                    >
                                        {subItems.name} <Icon name="arrow-nav" />
                                    </li>
                                );
                            })}
                    </ul>
                }
                {
                    <ul>
                        {subMenuState &&
                            Array.isArray(subMenuState) &&
                            subMenuState.map(item => {
                                return (
                                    <li key={item.name}>
                                        <Link
                                            onClick={() => {
                                                setSubMenuState(null);
                                                setSubActive(false);
                                                setActive(false);
                                                setMenuState(null);
                                                setSubMenuName(null);
                                            }}
                                            to={item.path || 'null'}
                                        >
                                            {item.name}
                                        </Link>
                                    </li>
                                );
                            })}
                    </ul>
                }
            </MobileMenu>
            <DesktopNav ref={navRef} className="" active={active}>
                <Link to="/">
                    <StaticImage
                        alt="western carolina family dentistry logo"
                        src="../../images/common/logo.png"
                        placeholder="none"
                        quality={100}
                        className="logo-desktop"
                    />
                </Link>
                <ul className="main-menu">
                    {navMenu.map(({ menu, name, path }) => {
                        if (menu) {
                            return (
                                <li
                                    onMouseEnter={() => {
                                        setActive(true);
                                        setSubActive(false);
                                        setMenuName(name);
                                        setMenuState(menu as any);
                                        setSubMenuState(null);
                                        setSubMenuName(null);
                                        setSubActive(false);
                                    }}
                                    key={name}
                                >
                                    <Link onClick={() => setActive(false)} to={path}>
                                        {name}
                                        <Icon name="arrow-nav-desktop" />
                                    </Link>
                                    {
                                        <DesktopMenu>
                                            {active &&
                                                menuName === name &&
                                                menuState &&
                                                Array.isArray(menuState) &&
                                                menuState.map(subItems => {
                                                    if (!subItems.sub_menu) {
                                                        return (
                                                            <li key={subItems.name}>
                                                                {subItems.path ? (
                                                                    <Link
                                                                        onClick={() => {
                                                                            setActive(false);
                                                                            setSubMenuName(null);
                                                                            setSubMenuState(null);
                                                                            setSubActive(false);
                                                                        }}
                                                                        to={subItems.path}
                                                                    >
                                                                        {subItems.name}
                                                                    </Link>
                                                                ) : (
                                                                    <a
                                                                        href={subItems.externalPath}
                                                                        rel="noopener noreferrer"
                                                                        target="__blank"
                                                                    >
                                                                        {subItems.name}
                                                                    </a>
                                                                )}
                                                            </li>
                                                        );
                                                    } else {
                                                        return (
                                                            <li
                                                                onMouseEnter={() => {
                                                                    setActive(true);
                                                                    setSubMenuName(subItems.name);
                                                                    setSubActive(true);
                                                                    setSubMenuState(
                                                                        subItems.sub_menu
                                                                    );
                                                                }}
                                                                className="category-item"
                                                                key={subItems.name}
                                                            >
                                                                {subItems.name}{' '}
                                                                <Icon
                                                                    className="category-arrow"
                                                                    name="arrow-nav"
                                                                />
                                                            </li>
                                                        );
                                                    }
                                                })}
                                        </DesktopMenu>
                                    }
                                </li>
                            );
                        } else {
                            return (
                                <Link key={name} to={path}>
                                    {name}
                                </Link>
                            );
                        }
                    })}
                    {subActive && subMenuName && (
                        <DesktopSubMenu subMenuName={subMenuName && subMenuName}>
                            {Array.isArray(subMenuState) &&
                                subMenuState.map(item => {
                                    return (
                                        <li key={item.name}>
                                            <Link to={item.path || ''}>{item.name}</Link>
                                        </li>
                                    );
                                })}
                        </DesktopSubMenu>
                    )}
                </ul>
                <div className="desktop-buttons">
                    <Link to="#contact">
                        <ButtonPrimary solid color="red">
                            CONTACT US
                        </ButtonPrimary>
                    </Link>
                </div>
            </DesktopNav>
        </StyledNav>
    );
};
